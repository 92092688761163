<template>
  <div class="Page">
    <div class="opt-bar flex">
      <el-button @click="handleNew" size="small" v-ripple>新增</el-button>
      <el-input size="small"
          @keyup.enter.native="onCurrentPageChange(1)"
          class="searcher-input"
          placeholder="关键词"
          clearable
          @clear="onSkeyClear"
          v-model="skey">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      <el-button @click="onCurrentPageChange(1)" type="primary" size="small"><i class="iconfont">&#xe622;</i></el-button>
    </div>
    <el-table :data="page.list" element-loading-text="Loading..."
      size="small"
      border fit highlight-current-row
      header-row-class-name="tableHeader"
      :height="tableHeight"
      :row-class-name="tableRowClass"
      row-key="id"
      default-expand-all
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column fixed align="center" label="Id" width="95">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="名称" width="130" align="left">
        <template slot-scope="scope">
          <span v-if="scope.row.upid > 0">&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <i :class="'iconfont '+scope.row.icon"></i>
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="图标" width="160" align="left">
        <template slot-scope="scope">
          {{ scope.row.icon}}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="60" align="center">
        <template slot-scope="scope">
          {{ transStat(scope.row.stat)}}
        </template>
      </el-table-column>
      <el-table-column label="上级" width="90" align="left">
        <template slot-scope="scope">
          {{ showUp(scope.row.upid)}}
        </template>
      </el-table-column>
      <el-table-column label="路径" width="160" align="left">
        <template slot-scope="scope">
          {{ scope.row.path}}
        </template>
      </el-table-column>
      <el-table-column label="排序" width="60" align="center" class="hidden-md-and-down">
        <template slot-scope="scope">
          {{ scope.row.idx}}
        </template>
      </el-table-column>
      <el-table-column label="备注"  align="left" class="hidden-md-and-down">
        <template slot-scope="scope">
          {{scope.row.note}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140" align="right" fixed="right">
        <template slot-scope="scope">
            <icon-btn v-if="!scope.row.upid" @click="handleNewChild(scope.$index, scope.row)"
            icon="iconnew"
            tip="新建子项"
            ></icon-btn>
            <icon-btn @click="handleEdit(scope.$index, scope.row)"
            icon="iconedit1"
            type="warning"
            ></icon-btn>
            <icon-btn @click="handleDelete(scope.$index, scope.row)"
            icon="icondel1"
            type="error"
            ></icon-btn>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager">
      <div class="pager-info">共 {{page.total}} 条 第 {{page.currentPage}}/{{page.pageCount}} 页</div>
      <el-pagination
        @size-change="onPageSizeChange"
        @current-change="onCurrentPageChange"
        :current-page="page.currentPage"
        :page-sizes="[20, 30, 40, 50,100]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total">
      </el-pagination>
      <div class="pager-time" v-if="queryTime > 0">查询{{queryTime}}毫秒</div>
    </div>
    <el-dialog :title="dialogTitle"
      width="45em"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      >
      <el-form class="from"
        :model="mform"
        :label-position="labelPosition"
        :status-icon="true"
        :show-message="true"
        :rules="mRules"
        ref="mform"
        :label-width="formLabelWidth"
      >
        <el-form-item class="item-label" label="名称"  prop="name">
          <el-input class="input" v-model="mform.name" autocomplete="off" ></el-input>
        </el-form-item>
        <el-form-item class="item-label" label="路径"  prop="path">
          <el-input class="input" v-model="mform.path" autocomplete="off" ></el-input>
        </el-form-item>
        <el-form-item class="item-label" label="图标(iconfont)"  prop="icon">
          <el-input class="input input-icon" v-model="mform.icon" autocomplete="off" ></el-input>
          <i :class="'iconDemo iconfont ' + mform.icon"></i>
        </el-form-item>
        <el-form-item class="item-label" label="上级模块" prop="upid">
          <el-select class="input" v-model="mform.upid" clearable placeholder="请选择">
          <el-option
            v-for="item in rootModules"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item class="item-label" label="状态" prop="stat">
          <el-switch
            class="switch"
            v-model="mform.stat"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="启用"
            inactive-text="禁用">
          </el-switch>
        </el-form-item>
        <el-form-item class="item-label" label="排序"  prop="idx">
          <el-input-number v-model="mform.idx" :min="1" :max="100"></el-input-number>
        </el-form-item>
        <el-form-item class="item-label" label="备注" prop="desc">
          <el-input class="input textarea" type="textarea" v-model="mform.desc"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" v-ripple>取 消</el-button>
        <el-button type="primary" @click="submitForm('mform')" v-ripple>确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import IconBtn from '../../components/btns/IconBtn'
export default {
  components: {    IconBtn  },
  data() {
    var validateName = (rule, value, callback) => {
      if (!value) {
         return callback(new Error('模块名称不能为空'));
      }
      callback();
    }
    return {
      tableHeight: 560,
      skey: '',
      page: {pg: 1, list:[], total: 0, pageSize: 100},
      queryTime: 0,

      curIndex: -1,
      dialogTitle: '新增模块',
      dialogFormVisible: false,
      formLabelWidth: '120px',
      labelPosition: 'right',
      mform : {
        name: '',
        path: '',
        icon: '',
        stat: 1,
        upid: '',
        idx: 5,
        desc: ''
      },
      mRules: {
        name: [
          { required: true, message: '请输入模块名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' },
          { validator: validateName, trigger: 'blur' }
        ],
        path: [
          { required: true, message: '请输入模块路径', trigger: 'blur' },
          { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
        ],
        icon: [
          { required: true, message: '请输入iconfont图标class', trigger: 'blur' },
        ]
      },
      rootModules: [],
    };
  },
  created(opt) {
    this.resize();
    window.onresize = this.resize();
    this.onCurrentPageChange(1);
  },
  methods: {
    handleNew(){
      this.curIndex = -1
      this.dialogFormVisible = true
      this.dialogTitle = '新增模块'
    },
    handleNewChild(rowIndex, row) {
      this.curIndex = rowIndex
      // this.mform = row
      this.mform = {stat: 1, idx: 5};
      this.mform.upid = this.transUpid(row.id)
      this.dialogTitle = '新增'+ row.name +'的子模块'
      this.dialogFormVisible = true
    },
    handleEdit(idx, row) {
      this.curIndex = idx
      this.mform = this.util.copyFrom(row, 'id,name, path, icon, stat, upid, idx, desc')
      this.mform.upid = this.transUpid(row.upid)
      this.dialogTitle = '修改模块-' + row.name
      this.dialogFormVisible = true
    },
    handleDelete(idx, row) {
      this.$confirm('请确定删除?' + row.name, '确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.post('api/sys/module/delete', {id: row.id}, res=>{
            if (res.code != 0) {
              this.$message.error('删除失败~' + res.msg);
              return;
            }
            if (row.up != null) {
              row.up.children.remove(row)
            } else {
              this.page.list.remove(row);
            }
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isEmpty(this.mform.upid)) {
            this.mform.upid = 0
          }
          this.post('api/sys/module/edit', this.mform, res=>{
            if (res.code != 0) {
              this.$message.error(res.msg)
              return
            }
            this.dialogFormVisible = false
            var ob = res.data
            this.$message.success('保存数据成功~')
            if (this.mform.id) {
              var row = this.util.findTreeNode(this.page.list, ob)
              if (row) {
                row = ob
              } else {
                this.onCurrentPageChange(1)
              }
            } else {
              if (ob.upid) {
                var up = this.util.find(this.page.list, ob.upid)
                if (up != null) {
                  up.children.push(ob);
                }
              }
            }
            // if (this.mform.id) {
            //   this.page.list.splice(this.curIndex, 1, ob);
            // } else {
            //   this.page.list.push(ob);
            //   if (ob.upid == 0) {
            //     this.rootModules.push({id: '' + val.id, name: val.name})
            //   }
            // }
          })
        } else {
          this.$message.error('error submit!!');
          return false;
        }
      });
    },
    resize() {
      var clientHeight = document.documentElement.clientHeight
      this.tableHeight = clientHeight - 160
    },
    onSkeyClear() {
      this.onCurrentPageChange(1)
    },
    async onCurrentPageChange(pg){
      var pm = {
        pg : pg,
        pageSize: this.page.pageSize,
        skey: this.skey
      }
      this.post('api/sys/modules', pm, res=>{
        if (res.code != 0) {
          this.$message.error('出错了~' + res.msg);
          return
        }
        this.queryTime = res.pageTime
        var treeList = this.util.makeTree(res.data.list);
        delete res.data.list
        this.page = res.data
        this.page.list = treeList
        // var list = this.page.list
        treeList.forEach((val, idx)=>{
          if (val.upid == 0) {
            this.rootModules.push({id: '' + val.id, name: val.name})
          }
        })
      })
    },
    onPageSizeChange(e) {
      this.page.pageSize = e;
      this.onCurrentPageChange(1)
    },
    showUp(upid) {
      var ob = this.util.find(this.rootModules, upid, 'id')
      if (ob) {
        return ob.name
      }
    },
    transUpid(upid) {
      if (upid == 0) {
        return ''
      } else {
        return '' + upid
      }
    },
    transStat(stat) {
      var res = ''
      switch(stat) {
        case 0:
          res = '禁用'
          break;
        case 1:
          res = '正常'
          break;
      }
      return res
    },
    tableRowClass({row, rowIndex}) {
      if (row.upid == 0) {
        return 'success-row'
      }
      if (row.stat === 0) {
        return "error-row"
      } else {
        if (rowIndex % 2 === 0) {
          return '';
        } else {
          return 'second-row'
        }
      }
    }

  } //methods
};
</script>

<style scoped lang="scss">
.Page {
  margin-top: 0px;

  .test {
    color: $test;
  }
}

.from{
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .item-label{
    text-align: left;
    margin-top: 3px;
  }

  .input{
    width: 20em;
    text-align: left;
  }

  .input-icon{
    width: 10em;
  }
  .textarea{
    margin-left: 5px;
  }
  .switch{
    text-align: left;
    margin-top: 5px;
    width: 20em;
  }
}

.iconDemo{
  margin-left: 10px;
  font-size: 1.5em;
}
</style>
